import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { showToaster } from 'store/app/actions'
import {
  useGetMyLegacyProfileQuery,
  useLoginMutation,
  useSendMagicLinkMutation,
} from 'store/api'

import BorderedContainer from 'components/BorderedContainer'
import { FormInput } from 'components/Form/FormInput'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import LinkButton from 'components/LinkButton'
import SubmitButton from 'components/SubmitButton'
import Title from 'components/Title'
import { Page } from 'components/Page'

import { LOGIN_VALIDATOR, LoginFormData } from './LogIn.utils'
import { joiResolver } from '@hookform/resolvers/joi'

import styles from './Login.module.scss'

export const LogIn: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { data: profile } = useGetMyLegacyProfileQuery()
  const [sendMagicLink, { isLoading: sendingLink }] = useSendMagicLinkMutation()
  const [login, { isLoading }] = useLoginMutation()

  const token = searchParams.get('token')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: joiResolver(LOGIN_VALIDATOR),
    mode: 'onSubmit',
  })
  useEffect(() => {
    if (!profile && token) {
      login(token)
        .unwrap()
        .then(() => {
          navigate('/')
        })
        .catch(() => {
          dispatch(
            showToaster({
              message: `Incorrect login link, please try again`,
              color: 'red',
            }),
          )
        })
    }
  }, [dispatch, login, navigate, profile, token])

  const onSubmit = async (body: LoginFormData) => {
    await sendMagicLink(body.email.trim().toLowerCase())
      .unwrap()
      .then(() =>
        navigate(`/login-requested/${body.email.trim().toLowerCase()}`),
      )
  }

  if (profile) {
    return <Navigate to="/" />
  }

  const disabled = isLoading || sendingLink

  return (
    <Page>
      <BorderedContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>Log In</Title>
          <p className={styles.text}>
            Please enter your email to log in and check your email. <br /> Click
            the link in the email you receive to complete the process.
          </p>
          <div className={styles.body}>
            <FormInput
              name="email"
              label="Email"
              control={control}
              className={styles.input}
              errorText={errors.email?.message}
            />
            <SubmitButton className={styles.submitButton} disabled={disabled}>
              LOG IN
            </SubmitButton>
            <LinkButton
              ghost
              className={styles.link}
              theme="primary"
              to={`/signup/`}
              disabled={disabled}
            >
              I DON'T HAVE AN ACCOUNT
            </LinkButton>
          </div>
        </form>
      </BorderedContainer>
      <ImageDecoration
        type={ImageDecorationType.GIRL_RUNNING}
        position="left"
      />
    </Page>
  )
}
