import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm } from 'react-hook-form'

import Title from 'components/Title'
import SubmitButton from 'components/SubmitButton'
import Conditional from 'components/Conditional'

import { Product } from 'data/Product'
import { ValidChildRecordData } from 'store/api/types/Product.types'
import { addToShoppingCart } from 'store/shop/actions'

import {
  buildDefaultValue,
  CUSTOM_PRODUCT_VALIDATOR,
  GENERIC_PRODUCT_VALIDATOR,
} from './BuyProduct.validator'

import { FormRecordSelect } from './FormRecordSelect'
import { FormQuantitySelect } from './FormQuantitySelect'

import styles from './BuyProductForm.module.scss'

type BuyProductFormProps = {
  product: Product
  isCustomProduct: boolean
  childrenApprovedRecords: {
    childName: string
    records: {
      id: string
      title: string
      url: string
    }[]
  }[]
}

export const BuyProductForm: FC<BuyProductFormProps> = ({
  product,
  isCustomProduct,
  childrenApprovedRecords,
}) => {
  const dispatch = useDispatch()

  const getValidator = () => {
    if (!isCustomProduct) {
      return GENERIC_PRODUCT_VALIDATOR
    }
    return CUSTOM_PRODUCT_VALIDATOR
  }

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<ValidChildRecordData>({
    resolver: joiResolver(getValidator()),
    mode: 'all',
  })

  const onSubmit = (body: ValidChildRecordData) => {
    const selectedRecord = childrenApprovedRecords
      .find((item) => item.childName === body.childRecord?.childName)
      ?.records.find((record) => record.id === body?.childRecord?.submissionId)

    const productCheckout = product && {
      product: product,
      childName: body.childRecord?.childName || '',
      submissionTitle: selectedRecord?.title || '',
      submissionId: selectedRecord?.id || '',
      submissionUrl: selectedRecord?.url || '',
      quantity: body.quantity,
    }

    productCheckout && dispatch(addToShoppingCart(productCheckout))
  }

  useEffect(() => {
    reset(buildDefaultValue())
  }, [reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.productForm}>
        <div className={styles.infoColumn}>
          <p className={styles.info}>{product?.info}</p>
          <Title className={styles.title}>{product?.title}</Title>
          <p className={styles.description}>{product?.description}</p>
          <Conditional shouldRender={isCustomProduct}>
            <FormRecordSelect
              name="childRecord"
              control={control}
              childrenApprovedRecords={childrenApprovedRecords}
            />
          </Conditional>
        </div>
        <div className={styles.buyColumn}>
          <p className={styles.priceTag}>${product?.price}</p>
          <div className={styles.container}>
            <FormQuantitySelect name="quantity" control={control} />
            <SubmitButton
              className={styles.button}
              endIcon={
                <img src="/assets/shopping-cart.svg" alt="shopping cart" />
              }
              disabled={!isValid}
            >
              ADD TO CART
            </SubmitButton>
          </div>
        </div>
      </div>
    </form>
  )
}
