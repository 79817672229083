import React, { FC, useState } from 'react'
import { CONFIG } from 'config'

import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import Link from 'components/Link'
import { ProfileMenu } from 'components/SwitchProfile'
import cn from 'classnames'

import Profiles from 'data/Profile'
import { useGetMyLegacyProfileQuery } from 'store/api'
import styles from './SelectRecordAttempt.module.scss'

export const SelectRecordAttempt: FC = () => {
  const { data: loggedProfile } = useGetMyLegacyProfileQuery()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = () => {
    const element = document.getElementById('avatarButton')
    setAnchorEl(element)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!CONFIG.FEATURE_784 || !Profiles.isParentProfile(loggedProfile)) {
    return (
      <BorderedContainer>
        <img
          src={'/assets/recordSubmission/submit-record-parent.png'}
          alt={'submitImage'}
        />
      </BorderedContainer>
    )
  }

  return (
    <BorderedContainer>
      <div className={styles.borderedContainerStyle}>
        <Title>Select Group or Individual Record Attempt</Title>
        <div className={styles.container}>
          <div className={styles.card} onClick={handleClick}>
            <p>
              For individual record attempts, click the Hippo icon in the top
              right corner to switch to your kid's profile.
            </p>
            <img
              className={styles.image}
              src={'/assets/recordSubmission/individual-record-attempt.png'}
              alt={'submitImage'}
            />
            <div className={cn(styles.btn, styles.shiftBtn)}>
              Individual attempt
            </div>
          </div>
          <Link className={styles.card} to={'/group-submission'}>
            <p>Select Group Record to submit an attempt for 2 or more kids.</p>
            <img
              className={styles.image}
              src={'/assets/recordSubmission/group-record-attempt.png'}
              alt={'submitImage'}
            />
            <div className={styles.btn}>Group attempt</div>
          </Link>
        </div>
      </div>
      <ProfileMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        profile={loggedProfile}
      />
    </BorderedContainer>
  )
}
