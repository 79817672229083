import React, { Fragment, useCallback, useMemo, useState } from 'react'
import {
  Box,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  useGetEventParticipantsQuery,
  useGetEventsNameListQuery,
  useUpdateEventParticipantStatusMutation,
} from 'store/api'
import {
  EventParticipant,
  EventParticipantStatus,
} from 'store/api/types/Event.types'
import { Input, Select } from 'components/Inputs'
import ParticipantRow from './ParticipantRow'
import { ReviewModal } from './ReviewModal'
import ParticipantTableHead, { HeadCell } from '../EventList/EventTableHead'
import { sortBy } from '../../../utils/arrays'
import Loader from '../../../components/Loader'
import { ExportEventPartisipantXlsxFileButton } from 'components/ExportEventPartisipantXlsxFileButton'

import styles from './ParticipantsList.module.scss'

export type Order = 'asc' | 'desc'

const headCells: HeadCell[] = [
  {
    id: 'parentFirstName',
    numeric: false,
    disablePadding: true,
    label: 'Parent First Name',
  },
  {
    id: 'parentLastName',
    numeric: false,
    disablePadding: true,
    label: 'Parent Last Name',
  },
  {
    id: 'parentEmail',
    numeric: false,
    disablePadding: true,
    label: 'Parent Email',
  },
  {
    id: 'kidsFirstName',
    numeric: false,
    disablePadding: true,
    label: 'KidsFirstName',
  },
  {
    id: 'kidsLastName',
    numeric: false,
    disablePadding: true,
    label: 'KidsLastName',
  },
  {
    id: 'kidsBirthDate',
    numeric: false,
    disablePadding: true,
    label: 'Kids Birth Date',
  },
  {
    id: 'instagram',
    numeric: false,
    disablePadding: true,
    label: 'Instagram',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'certificate',
    numeric: false,
    disablePadding: true,
    label: 'Certificate',
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: '',
  },
]

const sortData = (
  data: EventParticipant[],
  search: string,
  orderBy: string,
  order: Order,
) => {
  const s = search.toLowerCase()
  return sortBy(
    data.filter(
      (item) =>
        item.parentFirstName.toLowerCase().includes(s) ||
        item.kidsFirstName.toLowerCase().includes(s) ||
        item.kidsLastName.toLowerCase().includes(s),
    ) as any,
    orderBy,
    order,
  ) as any
}

const prepareData = (
  data: EventParticipant[],
  search: string,
  orderBy: string,
  order: Order,
): EventParticipant[] => {
  return sortData(data, search, orderBy, order)
}

const DEFAULT_SELECT_ITEM = { label: 'All Events', value: '' }

export const Participants = () => {
  const [reviewModalError, setReviewModalError] = useState('')
  const [eventId, setEventId] = useState('')
  const { data: eventTitles, isLoading: isEventLoading } =
    useGetEventsNameListQuery()
  const [updateStatus] = useUpdateEventParticipantStatusMutation()

  const { data, isLoading } = useGetEventParticipantsQuery(eventId)
  const [searchStr, setSearchStr] = useState('')
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('id')
  const [index, setIndex] = React.useState<number>(-1)

  const participants = useMemo(() => {
    return prepareData(data || [], searchStr, orderBy, order)
  }, [data, searchStr, orderBy, order])

  const selectedParticipant = participants[index]

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const onUpdateStatus = async (
    status: EventParticipantStatus,
    reviewComment?: string,
  ) => {
    try {
      updateStatus({
        id: selectedParticipant.id,
        status,
        reviewComment,
        eventId: selectedParticipant.eventId || '',
      })
      setIndex(index + 1)
      setReviewModalError('')
    } catch (e: any) {
      setReviewModalError(e?.data?.message)
    }
  }

  const closeReviewModal = () => {
    setIndex(-1)
    setReviewModalError('')
  }

  const eventTitleList = useMemo(() => {
    const newValue =
      eventTitles?.map((event) => ({ label: event.name, value: event.id })) ||
      []
    newValue.unshift(DEFAULT_SELECT_ITEM)
    return newValue
  }, [eventTitles])

  const isInReview =
    selectedParticipant?.status === EventParticipantStatus.IN_REVIEW

  const onNext = useCallback(() => {
    setIndex(index + 1)
  }, [index])

  const onPrev = useCallback(() => {
    setIndex(index - 1)
  }, [index])

  const onSelectParticipant = (idToFound: string) =>
    setIndex(
      participants.findIndex((participant) => participant.id === idToFound),
    )

  return (
    <Fragment>
      <Loader active={isLoading} className={styles.loader} />
      <ReviewModal
        //todo: use normal loading when PUBSUB will be introduced
        isLoading={false}
        // isLoading={isStatusUpdating}
        error={reviewModalError}
        //todo: use normal loading when PUBSUB will be introduced
        // disabled={isStatusUpdating || !isInReview}
        disabled={!isInReview}
        onChangeStatus={onUpdateStatus}
        participant={selectedParticipant}
        onNext={participants.length > index + 1 ? onNext : undefined}
        onPrev={index > 0 ? onPrev : undefined}
        onClose={closeReviewModal}
      />
      <Box className={styles.topBar}>
        <Select
          label={'Select Event'}
          items={eventTitleList}
          //todo: use normal loading when PUBSUB will be introduced
          // disabled={isEventLoading || isLoading || isStatusUpdating}
          disabled={isEventLoading || isLoading}
          value={eventId}
          onChange={setEventId}
        />
        <div className={styles.searchRow}>
          <Input
            label="Search"
            endAdornment={
              <InputAdornment position={'end'}>
                <SearchIcon />
              </InputAdornment>
            }
            type="search"
            value={searchStr}
            onChange={(e) => setSearchStr(e?.target?.value || '')}
          />
          <ExportEventPartisipantXlsxFileButton
            className={styles.export}
            eventId={eventId}
          />
        </div>
      </Box>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <ParticipantTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {participants.map((participant) => (
              <ParticipantRow
                key={participant.id}
                data={participant}
                onSelectParticipant={() => onSelectParticipant(participant.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  )
}
