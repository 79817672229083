import React, { useEffect } from 'react'
import { Navigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { joiResolver } from '@hookform/resolvers/joi'
import { Alert } from '@mui/material'

import { Page } from 'components/Page'
import BorderedContainer from 'components/BorderedContainer'
import Tooltip from 'components/Tooltip'
import SubTitle from 'components/SubTitle'
import Form from 'components/Form'
import Title from 'components/Title'
import { FormInput } from 'components/Form/FormInput'
import { FormSelect } from 'components/Form/FormSelect'
import SubmitButton from 'components/SubmitButton'
import { FormDatePicker } from 'components/Form/FormDatePicker'

import Profiles from 'data/Profile'

import {
  useGetChildQuery,
  useGetMyLegacyProfileQuery,
  useUpdateChildMutation,
} from 'store/api'
import {
  buildDefaultValue,
  CHILD_UPDATE_VALIDATOR,
  GENDER_OPTIONS,
} from './ChildProfileEdit.utils'
import { ChildUpdateBody } from 'store/api/types/Child.types'

import 'components/ChildProfileForm/child-profile-form.css'
import { FormLocationAutocomplete } from 'components/Form/FormLocationAutocomplete'

const GenderXTooltip: React.FC = () => (
  <Tooltip>
    <div className="child_form__gender-tooltip">
      <SubTitle className="f5 pb3">“Gender X” - A Note for Parents</SubTitle>
      <p className="dark-gray f7 mv1">
        At Kids World Records, we understand that not every kid fits into a box.
        The exploration of gender identity is a deeply personal experience, and
        our global conversation about it has shifted in recent years. We want to
        respect the experience of each child who interacts with our company. In
        an effort to make Kids World Records accessible to everyone, we have
        chosen to offer the third gender identity “Gender X,” instead of
        “non-binary”.
      </p>
      <p className="dark-gray f7 mv1">
        “Gender X” is a term implemented by multiple US State governments, used
        to encompass a gender that is not exclusively male or female, including,
        but not limited to, intersex, agender, amalgagender, androgynous,
        bigender, demigender, female-to-male, genderfluid, genderqueer,
        male-to-female, neutrois, nonbinary, pangender, third sex, transgender,
        transsexual, Two Spirit, and unspecified. Our mission is to foster a
        space where kids under the age of 16 can explore their interests with
        unbridled enthusiasm and creativity. We feel that if we force kids to
        make a decision about their gender identity before they are ready, or if
        we don’t offer a term that encompasses their true identity, we will not
        be living up to our own values and mission.
      </p>
      <p className="dark-gray f7 mv1">
        This cultural conversation is still developing, and we want to let you
        know we understand the tension that can arise between having to make a
        decision while still respecting one another. We are excited to work with
        you to address any concerns surrounding the gender identity and
        expression of your child that may arise while breaking records. We want
        to learn and grow together in order to more fully support our future
        generation of record-breaking humans.
      </p>
    </div>
  </Tooltip>
)

type ChildProfileParams = {
  id: string
}

const EditChildProfile: React.FC = () => {
  const { id = '' } = useParams<ChildProfileParams>()
  const navigate = useNavigate()
  const { data: childProfile } = useGetChildQuery(id, { skip: !id })
  const [updateChild] = useUpdateChildMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<ChildUpdateBody>({
    resolver: joiResolver(CHILD_UPDATE_VALIDATOR),
  })

  useEffect(() => {
    if (childProfile) {
      reset(buildDefaultValue(childProfile))
    }
  }, [reset, childProfile])

  const onSubmit = async (body: ChildUpdateBody) => {
    if (!id) {
      return
    }
    try {
      await updateChild({
        id,
        ...body,
        gender: body.gender.toUpperCase() as any,
      }).unwrap()
      navigate(-1)
    } catch (e) {
      setError('email', {
        message: 'Email has already been taken',
      })
    }
  }

  const { data: loggedProfile } = useGetMyLegacyProfileQuery()

  if (!loggedProfile || !Profiles.isParentProfile(loggedProfile)) {
    return <Navigate to="/" />
  }

  return (
    <Page>
      {childProfile && (
        <BorderedContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>Edit Child</Title>
            <Alert severity="warning" sx={{ margin: '0 0 1rem 0' }}>
              Do not create more than one profile per child. If you do the
              submission will be rejected, and your account may be banned.
            </Alert>
            <div className="flex flex-row">
              <div className="mr2 flex-auto">
                <FormInput
                  name="firstName"
                  label="First Name"
                  control={control}
                  errorText={errors.firstName?.message}
                />
              </div>
              <div className="ml2 flex-auto">
                <FormInput
                  name="lastName"
                  label="Last Name"
                  control={control}
                  errorText={errors.lastName?.message}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <FormSelect
                name="gender"
                items={GENDER_OPTIONS}
                label="Gender"
                control={control}
              />
              <GenderXTooltip />
            </div>
            <FormDatePicker
              name="dateOfBirth"
              maxDate={dayjs().add(-4, 'years').toDate()}
              label="Date of Birth"
              control={control}
              disabled
            />
            <div className="flex flex-column">
              <FormInput
                name="email"
                label="Email"
                control={control}
                errorText={errors.email?.message}
              />
            </div>
            <FormLocationAutocomplete
              control={control}
              label="City"
              name="location"
            />
            <div className="flex h-100 mv3 justify-end-l items-end items-start-l">
              <SubmitButton className="w-100 w4-l">Confirm</SubmitButton>
            </div>
          </Form>
        </BorderedContainer>
      )}
    </Page>
  )
}

export default EditChildProfile
