import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SignUpProfile } from 'typings/SignUpProfile'

const initialUser: SignUpProfile = {
  dateOfBirth: null,
  firstName: null,
  lastName: null,
  email: null,
  pin: null,
  howDidYouFoundUs: null,
  isTeacher: false,
}

interface SignUpState {
  profile: SignUpProfile
  isLoading: boolean
}

const initialState = { profile: initialUser, isLoading: false } as SignUpState

const signupSlice = createSlice({
  name: 'signup',
  initialState: initialState as SignUpState,
  reducers: {
    signUpAsync(state: SignUpState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    updateSignUpProfile(
      state: SignUpState,
      action: PayloadAction<SignUpProfile>,
    ) {
      state.profile.dateOfBirth = action.payload.dateOfBirth
      state.profile.firstName = action.payload.firstName
      state.profile.lastName = action.payload.lastName
      state.profile.email = action.payload.email
      state.profile.pin = action.payload.pin
      state.profile.howDidYouFoundUs = action.payload.howDidYouFoundUs
      state.profile.isTeacher = action.payload.isTeacher
    },
  },
})

export const { updateSignUpProfile, signUpAsync } = signupSlice.actions
export default signupSlice.reducer
