import { ChildProfile } from 'data/ChildProfile'
import { GenderV2 } from 'store/api/types/Gender.types'
import { RecordType } from 'store/api/types/Record.types'

export const buildRecordOwnerInfo = (record: any) => {
  const recordOwnerInfo =
    record.type === RecordType.SINGLE
      ? `${record?.gender?.replace('_', ' ')} - ${record?.ageGap} YEARS OLD`
      : `${record.category} GROUP CATEGORY`
  return `${recordOwnerInfo} - ${record?.location?.city}, ${
    record?.location?.state === 'N/A' ? '' : record?.location?.state
  } (${record?.location?.country})`
}

export const getIsRestricted = (profile: any, record: any) => {
  const age =
    new Date(
      new Date().getTime() - new Date(profile?.dateOfBirth || 0).getTime(),
    ).getFullYear() - 1970
  const ageBracket = record.ageGap.split('-').map((i: string) => parseInt(i))
  return (
    age < ageBracket[0] ||
    age > ageBracket[1] ||
    ((profile as ChildProfile)?.gender.toUpperCase() as GenderV2) !==
      record.gender
  )
}
