import React, { useEffect, useState, memo } from 'react'
import { useParams, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { CONFIG } from 'config'

import {
  useCreateRecordMutation,
  useGetMyLegacyProfileQuery,
  useGetPaymentForAllCountriesQuery,
  useGetProfileQuery,
  useGetRecordByIdQuery,
  useLazyGetChildSubmissionsQuery,
} from '../../store/api'

import BorderedContainer from 'components/BorderedContainer'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import Loader from 'components/Loader'
import Stepper from 'components/Stepper'
import Title from 'components/Title'
import { Page } from 'components/Page'
import BreakRecordRestriction from 'components/BreakRecordRestriction'
import Conditional from 'components/Conditional'
import PayPalModal from 'components/PayPalModal'

import {
  Person,
  ValidRecordDetailsFormData,
  ValidRecordSubmissionFormData,
  ValidRecordWitnessesFormData,
} from 'typings/RecordSubmissionFormData'

import BreakRecordInfo from './BreakRecordInfo'
import RecordDetails from './RecordDetails'
import Rules from './Rules'
import Witnesses from './Witnesses'
import { SelectRecordAttempt } from './SelectRecordAttempt'
import {
  Category,
  Record,
  RecordSubmissionParams,
} from 'store/api/types/Record.types'
import { AccountRole } from 'store/api/types/Auth.types'
import { PicturedPersonParams } from 'data/PicturedPerson'

import { BreakRecord as BreakRecordV2 } from './V2/BreakRecord'
import { PaymentForCountry } from 'store/api/types/PaymentForCountries.types'

type BreakRecordParams = {
  id?: string
  currentRecordId?: string
  recordId?: string
}

// const getWitnessesParams = (
//   witnessesEmails: string[] | undefined[],
// ): WitnessParams[] => {
//   const validWitness: WitnessParams[] = []
//   witnessesEmails.forEach((email: string | undefined) => {
//     if (email) {
//       validWitness.push({ email: email })
//     }
//   })
//
//   return validWitness
// }

const getPicturedPersons = (people: Person[]): PicturedPersonParams[] =>
  people.map((person: Person) => {
    return {
      name: person.name,
      email: person.email,
    }
  })

const prepareRecord = (
  recordSubmission: ValidRecordSubmissionFormData,
): RecordSubmissionParams => {
  const picturedPersons = recordSubmission?.picturedPersons
    ? getPicturedPersons(recordSubmission?.picturedPersons)
    : []

  // const witnesses = getWitnessesParams([
  //   recordSubmission.witness1Email,
  //   recordSubmission.witness2Email,
  // ])

  return {
    brokenOn: recordSubmission.brokenOn,
    description: recordSubmission.description,
    category: recordSubmission.category.toUpperCase() as Category,
    idea: recordSubmission.title,
    previousRecordId: recordSubmission.brokenRecordId,
    mediaLinks: recordSubmission.imageLinks,
    picturedPerson: picturedPersons,
    funFacts: recordSubmission.funFacts,
    location: recordSubmission.location,
    witnessEmails: [
      recordSubmission.witness1Email,
      recordSubmission.witness2Email,
    ].filter((email) => email),
    ambassadorId: recordSubmission.ambassadorId,
    paypalTransactionId: recordSubmission.paypalTransactionId,
  }
}

export const BreakRecordContent: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams<BreakRecordParams>()

  const [getSubmissions, { data: submissions }] =
    useLazyGetChildSubmissionsQuery()

  const { data: paidCountries } = useGetPaymentForAllCountriesQuery()
  const [wData, setWData] = useState<ValidRecordWitnessesFormData>()
  const [step, setStep] = useState<'rules' | 'details' | 'witnesses'>('rules')
  const [loading, setLoading] = useState(false)
  const [recordDetails, setRecordDetails] =
    useState<ValidRecordDetailsFormData | null>(null)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [shouldRestrict, setShouldRestrict] = useState(false)
  const [paidCountry, setPaidCountry] = useState<
    PaymentForCountry | undefined
  >()

  const [createRecordSubmission] = useCreateRecordMutation()
  const { data: record, isLoading } = useGetRecordByIdQuery(
    params.recordId || '',
  )

  const { state } = useLocation()

  const { data: authenticatedProfile, isLoading: isAuthenticating } =
    useGetMyLegacyProfileQuery()
  const { data: loggedProfile, isLoading: isFetching } = useGetProfileQuery()

  const handleRecordDetailsSubmit = (data: ValidRecordDetailsFormData) => {
    setRecordDetails(data)
    if (paidCountries) {
      setPaidCountry(
        paidCountries.find(
          (country) =>
            country.countryName === loggedProfile?.profile.location.country ||
            country.countryName === data.location.country,
        ),
      )
    }
    document.getElementById('scroller')?.scrollIntoView()
    setStep('witnesses')
  }

  const handleWitnessesSubmit = async (data: ValidRecordWitnessesFormData) => {
    const shouldUserPay =
      (submissions &&
        (submissions.IN_REVIEW.length > 0 ||
          submissions.APPROVED.length > 0)) ||
      !!paidCountry

    if (loggedProfile?.type === AccountRole.CHILD && recordDetails) {
      if (shouldUserPay) {
        setShowPaymentModal(true)
        setWData(data)
      } else {
        try {
          setLoading(true)
          if (data && recordDetails && loggedProfile) {
            const recordSubmission = prepareRecord({
              ...recordDetails,
              ...data,
              childProfileId: loggedProfile.profile.id,
              ideaId: record?.idea.id,
              brokenRecordId: params.recordId,
            })
            const res = (await createRecordSubmission(recordSubmission)) as {
              data: Record
            }
            setLoading(false)
            navigate(`/break-a-record/record-submitted/${res.data.id}`)
          }
        } catch (e) {
          setLoading(false)
        }
      }
    }
  }

  const createRecord = async (transactionID: string) => {
    setLoading(true)
    setShowPaymentModal(false)
    try {
      if (wData && recordDetails && loggedProfile) {
        const recordSubmission = prepareRecord({
          ...recordDetails,
          ...wData,
          childProfileId: loggedProfile.profile.id,
          ideaId: record?.idea.id,
          brokenRecordId: params.recordId,
          paypalTransactionId: transactionID,
        })
        const res = (await createRecordSubmission(recordSubmission)) as {
          data: Record
        }
        navigate(`/break-a-record/record-submitted/${res.data.id}`)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const handleStepBack = () => {
    switch (step) {
      case 'details':
        setStep('rules')
        break
      case 'witnesses':
        setStep('details')
        break
    }
  }

  const steps = ['Rules', 'Record Details', 'Witnesses']

  const getStepIndex = (): number => {
    switch (step) {
      case 'rules':
        return 0
      case 'details':
        return 1
      case 'witnesses':
        return 2
    }
  }

  useEffect(() => {
    if (loggedProfile?.type === AccountRole.CHILD) {
      getSubmissions(loggedProfile.profile.id)
    }
  }, [loggedProfile, getSubmissions])

  const shouldRenderContent =
    ((params.recordId && record) || !params.recordId) &&
    !isLoading &&
    !isFetching &&
    !isAuthenticating

  const isRecordHolder = Boolean(
    submissions?.IN_REVIEW.length || submissions?.APPROVED.length,
  )

  const onStepToDetails = () => {
    if (loggedProfile?.profile.ageGap === '17-130') {
      setShouldRestrict(true)
    } else {
      document.getElementById('scroller')?.scrollIntoView()
      setStep('details')
    }
  }

  return (
    <Page>
      <PayPalModal
        open={showPaymentModal}
        onCompetePayment={(transactionID) => createRecord(transactionID)}
        onClose={() => setShowPaymentModal(false)}
        isPaidCountry={!!paidCountry}
        paymentForPaidCountry={
          !!paidCountry ? parseFloat((paidCountry.payment / 100).toFixed(2)) : 5
        }
      />
      <BreakRecordRestriction
        isOpen={shouldRestrict}
        onClose={() => setShouldRestrict(false)}
      />
      <Conditional shouldRender={shouldRenderContent}>
        <div id="scroller">
          {!authenticatedProfile ? (
            <BreakRecordInfo />
          ) : (
            <>
              {loggedProfile?.type !== AccountRole.CHILD ? (
                <SelectRecordAttempt />
              ) : (
                <BorderedContainer>
                  <Title>Submit a Record</Title>
                  <Conditional shouldRender={getStepIndex() !== 0}>
                    <Stepper activeStep={getStepIndex()} steps={steps} />
                  </Conditional>
                  <Conditional shouldRender={step === 'rules'}>
                    <Rules
                      title={record?.idea.title}
                      shouldPay={isRecordHolder}
                      onSubmit={onStepToDetails}
                      ideaTitle={state?.ideaTitle}
                    />
                  </Conditional>
                  <Conditional shouldRender={step === 'details'}>
                    <RecordDetails
                      record={
                        record && {
                          title: record.idea.title,
                          category: record.category,
                        }
                      }
                      childProfile={loggedProfile.profile || null}
                      isRecordHolder={isRecordHolder}
                      onBack={handleStepBack}
                      onSubmit={handleRecordDetailsSubmit}
                      ideaTitle={state?.ideaTitle}
                      state={recordDetails}
                    />
                  </Conditional>
                  <Conditional shouldRender={step === 'witnesses'}>
                    <Witnesses
                      onBack={handleStepBack}
                      onSubmit={handleWitnessesSubmit}
                      loading={loading}
                    />
                  </Conditional>
                </BorderedContainer>
              )}
            </>
          )}
          <ImageDecoration type={ImageDecorationType.BOY_JUMPING_ROPE} />
        </div>
      </Conditional>
      <Conditional shouldRender={!shouldRenderContent}>
        <Loader
          active
          className="z-999"
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
        />
      </Conditional>
    </Page>
  )
}

export const BreakRecordComponent: React.FC = () => {
  if (CONFIG.FEATURE_296) {
    return <BreakRecordV2 />
  }
  return <BreakRecordContent />
}

export const BreakRecord = memo(BreakRecordComponent)
