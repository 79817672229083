import { Pin } from './Pin'
import signUpSchema from 'validation/signUpSchema'

export interface SignUpProfile {
  firstName: string | null
  lastName: string | null
  email: string | null
  dateOfBirth: Date | null
  pin: Pin | null
  howDidYouFoundUs: string | null
  isTeacher: boolean
}

export interface ValidSignUpProfile {
  firstName: string
  lastName: string
  email: string
  dateOfBirth: Date
  pin: Pin | null
  howDidYouFoundUs: string
  isTeacher: boolean
}

const isValid = (
  signUpProfile: SignUpProfile,
): signUpProfile is ValidSignUpProfile =>
  signUpSchema.isValidSync(signUpProfile)

const exp = { isValid }

export default exp
