import React from 'react'

import Conditional from 'components/Conditional'

import IconButton from 'components/IconButton'
import { useGetMyLegacyProfileQuery } from 'store/api'
import Profiles from '../../data/Profile'

interface AdminCKEditorPencilProps {
  onClick: () => void
}

export const AdminCKEditorPencil: React.FC<AdminCKEditorPencilProps> = ({
  onClick,
}) => {
  const { data: profile, isSuccess } = useGetMyLegacyProfileQuery()
  const isAdminLogged =
    isSuccess && Boolean(profile) && Profiles.isAdminProfile(profile)

  return (
    <Conditional shouldRender={isAdminLogged}>
      <IconButton
        name="edit"
        type="button"
        onClick={onClick}
        label="Edit content"
      />
    </Conditional>
  )
}
