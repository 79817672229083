import { Child } from './Child.types'

export enum AccountRole {
  ADMIN = 'ADMIN',
  PARENT = 'PARENT',
  CHILD = 'CHILD',
}

export type AuthProfile = {
  id: string
  firstName: string
  lastName: string
  email?: string
  role: AccountRole
}

export type MyProfile = {
  type: Omit<AccountRole, 'ADMIN'>
  profile: Child
}

export type ResetPinBody = {
  token: string
  pin: string
}
