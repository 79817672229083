export const CONFIG = {
  API_URL: process.env.REACT_APP_API_URL,
  FUNCTION_URL: process.env.REACT_APP_FUNCTIONS_URL,
  SHOPIFY_DOMAIN: process.env.REACT_APP_SHOPIFY_DOMAIN || '',
  SHOPIFY_STOREFRONT_ACCESS_TOKEN:
    process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN || '',
  // this is new Submit (not ready)
  FEATURE_296: Boolean(process.env.REACT_APP_FEATURE_296),
  // Ambassador
  FEATURE_324: Boolean(process.env.REACT_APP_FEATURE_324),
  // QUIZZ
  FEATURE_341: Boolean(process.env.REACT_APP_FEATURE_341),
  // Group Record
  FEATURE_784: Boolean(process.env.REACT_APP_FEATURE_784),
  // Quiz Score Board
  FEATURE_362: Boolean(process.env.REACT_APP_FEATURE_362),
  // World Map
  FEATURE_513: Boolean(process.env.REACT_APP_FEATURE_513),
  // Edit Cms Admin
  FEATURE_931: Boolean(process.env.REACT_APP_FEATURE_931),
  // Export records
  FEATURE_741: Boolean(process.env.REACT_APP_FEATURE_741),
  // Amdassadors table
  FEATURE_328: Boolean(process.env.REACT_APP_FEATURE_328),
  // Users Managment
  FEATURE_1054: Boolean(process.env.REACT_APP_FEATURE_1054),
  // Apply Analytics
  APPLY_ANALYTICS: process.env.REACT_APP_APPLY_ANALYTICS,
}
