import React from 'react'
import { Navigate } from 'react-router'

import { useGetMyLegacyProfileQuery } from 'store/api'

import Profiles from 'data/Profile'

const DefaultRedirect: React.FC = () => {
  const { data: profile, isSuccess } = useGetMyLegacyProfileQuery()
  const isAdminLogged = profile && Profiles.isAdminProfile(profile)

  return (
    <>
      {isSuccess && isAdminLogged ? (
        <Navigate to="/admin/submissions" />
      ) : (
        <Navigate to="/" />
      )}
    </>
  )
}

export default DefaultRedirect
