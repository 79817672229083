import React, { FC, Fragment } from 'react'
import { IconButton } from '@mui/material'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

import Conditional from 'components/Conditional'

import styles from './RecordCard.module.scss'
import Profiles from '../../../../../data/Profile'
import { useGetMyLegacyProfileQuery } from 'store/api'

interface PriorityButtonProps {
  priority: 0 | 1
}

export const PriorityButton: FC<PriorityButtonProps> = ({ priority }) => {
  const { data: profile } = useGetMyLegacyProfileQuery()
  const isAdmin = Boolean(profile && Profiles.isAdminProfile(profile))

  if (!isAdmin) {
    return <Fragment />
  }

  return (
    <IconButton onClick={(e) => e.preventDefault()}>
      <Conditional shouldRender={priority === 1}>
        <ArrowDownward className={styles.downWardIcon} />
      </Conditional>
      <Conditional shouldRender={priority === 0}>
        <ArrowUpward className={styles.upWardIcon} />
      </Conditional>
    </IconButton>
  )
}
