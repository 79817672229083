import { AdminProfile } from './AdminProfile'
import { ChildProfile } from './ChildProfile'
import { ParentProfile } from './ParentProfile'

export interface Profile {
  __typename: 'ParentProfile' | 'ChildProfile' | 'AdminProfile'
  dateOfBirth: string
  email: string | null
  firstName: string
  id: string
  lastName: string
  tokens: string[]
}

export type ProfileAvatarType = 'parent' | 'boy' | 'girl' | 'gender_x' | 'admin'

export interface ProfileErrors {
  dateOfBirth?: string[]
  email?: string[]
  firstName?: string[]
  lastName?: string[]
  location?: {
    city?: string[]
    country?: string[]
    state?: string[]
  }
  pin?: string[]
}

const getAvatar = (profile: Profile): ProfileAvatarType => {
  if (isChildProfile(profile)) {
    return getChildAvatar(profile)
  } else if (isAdminProfile(profile)) {
    return 'admin'
  } else {
    return 'parent'
  }
}

const getChildAvatar = (profile: ChildProfile): ProfileAvatarType =>
  profile.gender === 'female'
    ? 'girl'
    : profile.gender === 'male'
      ? 'boy'
      : 'gender_x'

const getFullName = (profile?: Profile | null): string =>
  `${profile?.firstName} ${profile?.lastName}`

const isAdminProfile = (profile?: Profile): profile is AdminProfile =>
  profile?.__typename === 'AdminProfile'

const isChildProfile = (profile?: Profile): profile is ChildProfile =>
  profile?.__typename === 'ChildProfile'

const isParentProfile = (profile?: Profile): profile is ParentProfile =>
  profile?.__typename === 'ParentProfile'

const exp = {
  getAvatar,
  getChildAvatar,
  getFullName,
  isAdminProfile,
  isChildProfile,
  isParentProfile,
}

export default exp
