import * as localStorage from '../utils/localStorage'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { kwrApi } from '../store/api'

export const useLogout = () => {
  const dispatch = useDispatch()

  const handleLogout = useCallback(() => {
    localStorage.remove('AUTH_TOKEN')
    dispatch(kwrApi.util.invalidateTags(['MyProfile']))
  }, [dispatch])

  return { handleLogout }
}
