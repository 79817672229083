import React, { createContext } from 'react'
import { useGetMyLegacyProfileQuery } from '../../store/api'
import * as localStorage from 'utils/localStorage'

interface AuthenticationContextData {
  isAuthenticated: boolean
}

export const AuthenticationContext = createContext<AuthenticationContextData>({
  isAuthenticated: false,
})

const AuthenticationProvider = ({
  children,
}: {
  children: any
}): JSX.Element => {
  const { data: profile, isError } = useGetMyLegacyProfileQuery()
  if (isError) {
    localStorage.remove('AUTH_TOKEN')
  }

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: Boolean(profile),
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
