import React from 'react'
import { useLocation } from 'react-router-dom'

import { useGetMyLegacyProfileQuery, useGetProductsQuery } from 'store/api'

import { ProductCard } from 'components/Card'
import Loader from 'components/Loader'
import Title from 'components/Title'
import { PageWrapper } from 'components/PageWrapper'

import './browse-products.css'

const BrowseProducts: React.FC = () => {
  const url = new URLSearchParams(useLocation().search)
  const token = url.get('token') || ''

  const { data: loggedProfile } = useGetMyLegacyProfileQuery()

  const { data: products = [], isLoading } = useGetProductsQuery()

  return (
    <PageWrapper>
      <div
        className="w-100 h-100 center pv3 ph3 ph5-m ph5-l"
        style={{ maxWidth: '74.4rem' }}
      >
        <Loader
          active={isLoading}
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
          className="z-999"
        />
        {!isLoading && (
          <>
            <Title>Kids World Records Store</Title>
            <div className="f4 nunito-regular dark-gray pb4">
              Welcome to the Kids World Records shop! Record holders can
              purchase exclusive Kids World Record medals and personalized
              certificates with a gold embossed seal to commemorate their
              achievements. The annual Kids World Record book is also available
              to one and all! They make great gifts and keepsakes!
            </div>
            <div className="browse-products__grid">
              {products.map((product) => (
                <ProductCard
                  isLogged={!!loggedProfile}
                  key={product.id}
                  product={product}
                  token={token}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  )
}

export default BrowseProducts
