import React, { FC, useCallback, useEffect, useState, Fragment } from 'react'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { Page } from 'components/Page'
import Title from 'components/Title'
import Conditional from 'components/Conditional'
import SubTitle from 'components/SubTitle'
import Button from 'components/Button'
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded'
import TimerRoundedIcon from '@mui/icons-material/TimerRounded'
import ReplayIcon from '@mui/icons-material/Replay'
import { PickTypeModal } from '../components/PickTimeModal'
import { AuthorizationModal } from '../components/AuthorizationModal'
import { StartModal } from '../components/StartModal'
import { CompleteAttemptModal } from '../components/CompleteAttemptModal'
import { ParentPermissionModal } from '../components/ParentPermissionModal'
import { AnswerStatus, Question } from '../components/Question'
import { RestartModal } from '../components/RestartModal'
import { SuggestQuiz } from '../components/SuggestQuiz'
import { QuizRecordOwners } from '../components/QuizRecordOwners'
import { Timer } from '../components/Timer'
import {
  useCompleteAttemptMutation,
  useCreateAttemptMutation,
  useGetAuthProfileQuery,
  useGetProfileQuery,
  useGetQuizByIdQuery,
  useRestartAttemptMutation,
} from 'store/api'
import {
  AnswerResult,
  AttemptStatus,
  AttemptType,
  QuizAttempt,
} from 'store/api/types/Quiz.types'
import { AccountRole } from 'store/api/types/Auth.types'
import styles from './Quiz.module.scss'

type QuizPageParams = {
  id: string
}

export const Quiz: FC = () => {
  const { id = '' } = useParams<QuizPageParams>()
  const { data: quiz } = useGetQuizByIdQuery(id, { skip: !id })
  const [createAttempt, { isLoading }] = useCreateAttemptMutation()
  const [restartAttempt, { isLoading: isRestarting }] =
    useRestartAttemptMutation()
  const [completeAttempt, { isLoading: isCompleting }] =
    useCompleteAttemptMutation()
  const [openedModal, setOpenedModal] = useState<boolean>(false)
  const [isAuthorizationModalOpen, setIsAuthorizationModalOpen] =
    useState(false)
  const [isStartModalOpen, setIsStartModalOpen] = useState(false)
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
  const [isParentPermissionModalOpen, setIsParentPermissionModalOpen] =
    useState(false)
  const [type, setType] = useState<'amount' | 'speed'>('amount')
  const [attempt, setAttempt] = useState<QuizAttempt | null>(null)
  const [shouldRestart, setShouldRestart] = useState(false)
  const [answer, setAnswer] = useState<string>('')
  const [answerResult, setAnswerResult] = useState<AnswerResult | null>(null)
  const [newRecordId, setNewRecordId] = useState<string>('')
  const [correctAmount, setCorrectAmount] = useState<number>(0)
  const [wrongAmount, setWrongAmount] = useState<number>(0)
  const [allowSwitchToNext, setAllowSwitchToNext] = useState(false)
  const [isAnswering, setIsAnswering] = useState(false)
  const [timeToShowCorrectAnswerPassed, setTimeToShowCorrectAnswerPassed] =
    useState(false)
  const [index, setIndex] = useState(0)
  const [question, setQuestion] = useState<{
    id: string
    image: string
    answers: { id: string; text: string }[]
    correctAnswer: string
  } | null>(null)
  const [questionsWithChildAnswers, setQuestionsWithChildAnswers] = useState<
    {
      id: string
      answer: string
      timestamp: Date
    }[]
  >([])

  const onStop = useCallback(async () => {
    try {
      if (attempt) {
        const recordId = await completeAttempt({
          id: attempt.id,
          questions: questionsWithChildAnswers,
        }).unwrap()
        setNewRecordId(recordId)
        setAttempt(null)
        resetAttempt()
        setIsCompleteModalOpen(true)
      }
    } catch (e) {
      console.error(e)
    }
  }, [attempt, completeAttempt, questionsWithChildAnswers])

  const showNextQuestion = useCallback(() => {
    setAllowSwitchToNext(false)
    setTimeToShowCorrectAnswerPassed(false)
    if (attempt && index < attempt.questions.length - 1) {
      setAnswer('')
      setAnswerResult(null)
      setIndex(index + 1)
    } else {
      onStop()
    }
  }, [attempt, index, onStop])

  useEffect(() => {
    if (attempt) {
      setShouldRestart(false)
      setQuestion({
        id: attempt.questions[index].id,
        image: attempt.questions[index].image,
        answers: attempt.questions[index].answers,
        correctAnswer: attempt.questions[index].correctAnswer,
      })

      if (type === 'amount') {
        const timeoutId = setTimeout(() => {
          setAllowSwitchToNext(true)
        }, 10000)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [attempt, index, showNextQuestion, type])

  useEffect(() => {
    if (!isAnswering && allowSwitchToNext) {
      setWrongAmount(wrongAmount + 1)
      showNextQuestion()
      return
    }
    if (timeToShowCorrectAnswerPassed) {
      showNextQuestion()
    }
  }, [
    allowSwitchToNext,
    timeToShowCorrectAnswerPassed,
    isAnswering,
    showNextQuestion,
    wrongAmount,
  ])

  const onAnswer = async (childAnswer: string) => {
    try {
      if (attempt && question) {
        setIsAnswering(true)
        setAnswer(childAnswer)
        const correctAnswer = question.answers.find(
          (ans) => ans.id === question.correctAnswer,
        )?.text
        const result = {
          attemptStatus: AttemptStatus.IN_PROGRESS,
          answerStatus: childAnswer === correctAnswer ? 'CORRECT' : 'WRONG',
        }
        setAnswerResult(result as AnswerResult)
        setQuestionsWithChildAnswers([
          ...questionsWithChildAnswers,
          {
            id: question.id,
            answer: childAnswer,
            timestamp: new Date(),
          },
        ])

        result.answerStatus === AnswerStatus.CORRECT
          ? setCorrectAmount(correctAmount + 1)
          : setWrongAmount(wrongAmount + 1)

        setTimeout(() => {
          setTimeToShowCorrectAnswerPassed(true)
          setIsAnswering(false)
        }, 1000)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const { data } = useGetProfileQuery()
  const { data: profile } = useGetAuthProfileQuery()
  const isChild = data?.type === AccountRole.CHILD

  const onStart = () => {
    isChild ? setIsStartModalOpen(true) : setIsAuthorizationModalOpen(true)
  }

  const resetAttempt = () => {
    setIndex(0)
    setCorrectAmount(0)
    setWrongAmount(0)
    setAnswer('')
    setAnswerResult(null)
    setShouldRestart(true)
    setQuestionsWithChildAnswers([])
  }

  const onRestart = async () => {
    try {
      if (attempt) {
        const newAttempt = await restartAttempt(attempt.id).unwrap()
        setAttempt(newAttempt)
        resetAttempt()
        setIsRestartModalOpen(false)
      }
    } catch (e) {
      setAttempt(null)
      console.error(e)
    }
  }

  const onNext = async () => {
    if (data?.profile) {
      setIsStartModalOpen(false)
      setIsParentPermissionModalOpen(true)
    } else {
      await onPlayQuiz()
    }
  }

  const onPlayQuiz = async () => {
    try {
      if (quiz) {
        const newAttempt = await createAttempt({
          quizId: quiz.id,
          type: type.toUpperCase() as AttemptType,
        }).unwrap()
        setAttempt(newAttempt)
        setIsStartModalOpen(false)
        setIsParentPermissionModalOpen(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Page className={styles.page}>
      {quiz && (
        <Fragment>
          <div className={styles.header}>
            <Title className={styles.title}>{quiz.title}</Title>

            <Conditional shouldRender={Boolean(quiz.sponsorLogo)}>
              <div className={styles.sponsoredBy}>
                <SubTitle>Sponsored by</SubTitle>
                <div className={styles.sponsor_logo}>
                  <img src={quiz.sponsorLogo} alt="Sponsor" />
                </div>
              </div>
            </Conditional>
          </div>

          <PickTypeModal
            isModalOpen={openedModal}
            selectedType={type}
            onClose={() => setOpenedModal(false)}
            onTypeSelect={setType}
          />
          <AuthorizationModal
            isOpenModal={isAuthorizationModalOpen}
            isParent={profile?.role === AccountRole.PARENT}
            onClose={() => setIsAuthorizationModalOpen(false)}
            onPlay={() => {
              setIsAuthorizationModalOpen(false)
              setIsStartModalOpen(true)
            }}
          />
          <StartModal
            isOpenModal={isStartModalOpen}
            child={data?.profile}
            isParent={profile?.role === AccountRole.PARENT}
            onClose={() => setIsStartModalOpen(false)}
            onPlay={onNext}
            isLoading={isLoading}
            type={type}
          />
          <RestartModal
            isOpenModal={isRestartModalOpen}
            onClose={() => setIsRestartModalOpen(false)}
            onRestart={onRestart}
            isLoading={isRestarting}
          />
          <CompleteAttemptModal
            isOpenModal={isCompleteModalOpen}
            recordId={newRecordId}
            onClose={() => setIsCompleteModalOpen(false)}
          />
          <ParentPermissionModal
            isOpenModal={isParentPermissionModalOpen}
            onClose={() => setIsParentPermissionModalOpen(false)}
            onPlay={onPlayQuiz}
            isLoading={isLoading}
          />

          <div className={styles.switchType}>
            <Conditional shouldRender={type === 'amount'}>
              <div className={styles.typeDescription}>
                <DashboardCustomizeRoundedIcon className={styles.typeIcon} />
                <p className={styles.description}>
                  Rewarding the most identified
                </p>
              </div>
            </Conditional>
            <Conditional shouldRender={type === 'speed'}>
              <div className={styles.typeDescription}>
                <TimerRoundedIcon className={styles.typeIcon} />
                <p className={styles.description}>Rewarding based on speed</p>
              </div>
            </Conditional>
            <button
              className={cn(styles.underlinedLinkButton, styles.description)}
              onClick={() => setOpenedModal((modal) => !modal)}
            >
              Switch Quiz Type
            </button>
          </div>

          <p className={styles.description}>{quiz.shortDescription}</p>
          <p>{quiz.description}</p>
          <Conditional shouldRender={!attempt}>
            <div className={styles.buttonLine}>
              <Button className={styles.greenButton} onClick={onStart}>
                START QUIZ
              </Button>
              <div className={styles.line}></div>
              <Conditional shouldRender={type === 'speed'}>
                <Timer
                  seconds={quiz.duration}
                  onZero={onStop}
                  isPaused={true}
                />
              </Conditional>
            </div>
            <Question
              imgUrl={quiz.previewImageUrl}
              answer={''}
              answers={quiz.question.answers}
              onAnswer={onAnswer}
              correctAmount={0}
              wrongAmount={0}
              answerStatus={'WRONG' as any}
              disabled={true}
              showImageDescription={quiz.title === 'Dinosaurs'}
            />
          </Conditional>
          <Conditional shouldRender={!!attempt}>
            <div className={styles.buttonLine}>
              <Conditional shouldRender={type === 'speed'}>
                <Button
                  className={styles.button}
                  startIcon={<ReplayIcon className={styles.icon} />}
                  onClick={() => setIsRestartModalOpen(true)}
                  disabled={isCompleting}
                >
                  Restart
                </Button>
              </Conditional>
              <Conditional shouldRender={type === 'amount'}>
                <Button
                  className={styles.button}
                  onClick={onStop}
                  disabled={isCompleting}
                >
                  Stop
                </Button>
              </Conditional>
              <div className={styles.line}></div>
              <Conditional shouldRender={type === 'speed'}>
                <Timer
                  seconds={quiz.duration}
                  onZero={onStop}
                  isPaused={isCompleting}
                  shouldRestart={shouldRestart}
                />
              </Conditional>
            </div>
            {question && (
              <Question
                imgUrl={question.image}
                answer={answer}
                answers={question.answers.map((ans) => ans.text)}
                onAnswer={onAnswer}
                correctAmount={correctAmount}
                wrongAmount={wrongAmount}
                totalCount={attempt?.questions.length}
                answerStatus={
                  answerResult?.answerStatus || ('IN_PROGRESS' as any)
                }
                disabled={isAnswering || !!answerResult || isCompleting}
              />
            )}
          </Conditional>

          <QuizRecordOwners quizId={quiz.id} />
          <SuggestQuiz />
        </Fragment>
      )}
    </Page>
  )
}
