import React, { useMemo, ReactNode } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import cn from 'classnames'

import styles from './Input.module.scss'

export interface InputProps {
  inputRef?: any
  label?: string
  name?: string
  type?: string
  value?: string
  shrink?: boolean
  multiple?: boolean
  placeholder?: string
  errorText?: string
  select?: boolean
  disabled?: boolean
  multiline?: boolean
  className?: string
  defaultValue?: string | number
  maxLength?: number
  minRows?: number
  inputMode?: 'numeric' | 'none'
  children?: ReactNode
  endAdornment?: ReactNode
  onFocus?: TextFieldProps['onFocus']
  onChange?: TextFieldProps['onChange']
  onKeyDown?: TextFieldProps['onKeyDown']
  required?: boolean
  hiddenLabel?: boolean
}

export const Input = ({
  label,
  maxLength,
  type,
  shrink,
  errorText,
  className,
  children,
  name,
  value,
  select = false,
  multiple,
  placeholder,
  multiline = false,
  minRows,
  onFocus,
  inputRef,
  onKeyDown,
  inputMode,
  onChange,
  defaultValue,
  endAdornment,
  disabled,
  required,
  hiddenLabel,
}: InputProps) => {
  const SMALL_INPUT_PROPS = useMemo(
    () => ({
      maxLength,
      inputMode,
      multiple,
    }),
    [maxLength, inputMode, multiple],
  )

  const INPUT_PROPS = useMemo(
    () => ({
      disableUnderline: true,
      endAdornment,
    }),
    [endAdornment],
  )

  const shrinkForValue = shrink || value ? Boolean(value) : undefined

  const INPUT_LABEL_PROPS = useMemo(
    () => ({
      shrink: shrinkForValue,
    }),
    [shrinkForValue],
  )

  return (
    <TextField
      fullWidth
      className={cn(styles.input, className)}
      variant="filled"
      name={name}
      key={defaultValue}
      defaultValue={defaultValue}
      placeholder={placeholder}
      disabled={disabled}
      onFocus={onFocus}
      onChange={onChange}
      onKeyDown={onKeyDown}
      type={type}
      value={value}
      multiline={multiline}
      label={label}
      error={!!errorText}
      helperText={errorText}
      InputProps={INPUT_PROPS}
      InputLabelProps={INPUT_LABEL_PROPS}
      inputProps={SMALL_INPUT_PROPS}
      inputRef={inputRef}
      select={select}
      minRows={minRows}
      required={required}
      hiddenLabel={hiddenLabel}
    >
      {children}
    </TextField>
  )
}
