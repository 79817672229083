import React, { FC, Fragment, memo } from 'react'
import { IconButton } from '@mui/material'
import { Favorite } from '@mui/icons-material'

import Profiles from '../../../../../data/Profile'
import {
  useGetMyLegacyProfileQuery,
  useLikeRecordMutation,
} from '../../../../../store/api'

import styles from './RecordCard.module.scss'

interface LikeButtonProps {
  recordId: string
  likeAmount: number
  isLiked?: boolean
}

export const LikeButtonComponent: FC<LikeButtonProps> = ({
  likeAmount,
  recordId,
  isLiked,
}) => {
  const [likeRecord, { isLoading, data }] = useLikeRecordMutation()
  const { data: profile } = useGetMyLegacyProfileQuery()
  const isAuthorized = Boolean(profile)
  const isAdmin = Boolean(profile && Profiles.isAdminProfile(profile))

  const totalLikes = data?.totalLikes ?? likeAmount
  const calcIsLiked = data?.isLiked ?? isLiked

  if (isAdmin) {
    return <Fragment />
  }

  return (
    <div className={styles.likeButton}>
      <span>{totalLikes}</span>
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          likeRecord(recordId)
        }}
        disabled={!isAuthorized || isLoading}
      >
        <Favorite className={calcIsLiked ? styles.liked : styles.icon} />
      </IconButton>
    </div>
  )
}

export const LikeButton = memo(LikeButtonComponent)
