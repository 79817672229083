import React, { FC, useState } from 'react'
import { Interweave } from 'interweave'
import BorderedContainer from 'components/BorderedContainer'
import Title from 'components/Title'
import { Page } from 'components/Page'
import Button from 'components/Button'
import stylesList from './ListOfRecordsPage.module.scss'
import { useGetCmsRecordByContentNameQuery } from 'store/api'
import { AdminCKEditorPencil, CmsEditForm } from 'components/AdminCKEditor'
import { useGetMyLegacyProfileQuery } from 'store/api'
import Profiles from '../../data/Profile'
import Conditional from 'components/Conditional'
import Loader from 'components/Loader'
import { CmsDataToUpdate } from '../../store/api/types/Record.types'
import useToggle from 'hooks/useToggle'
import styles from 'components/AdminCKEditor/AdminCKEditor.module.scss'

const dateYear = new Date().getFullYear()
const AVAILABLE_PAGE_TITLE = `Available ${dateYear} School & Camp Records`
const RESERVED_PAGE_TITLE = `Reserved ${dateYear} School & Camp Records`
const AVAILABLE_GROUP_EVENTS_PAGE = 'available_group_events'
const RESERVED_GROUP_EVENTS_PAGE = 'reserved_group_events'

export const ListOfRecordsPage: FC = () => {
  const [isAvailable, setIsAvailable] = useState(true)
  const { data: profile, isSuccess } = useGetMyLegacyProfileQuery()
  const isAdminLogged =
    isSuccess && Boolean(profile) && Profiles.isAdminProfile(profile)
  const [isEditing, ClickEditToggle] = useToggle()
  const {
    data: contentDataAvailable,
    isLoading: isLoadingAvailable,
    isUninitialized: isUninitializedAvailable,
  } = useGetCmsRecordByContentNameQuery(AVAILABLE_GROUP_EVENTS_PAGE)
  const {
    data: contentDataReserved,
    isLoading: isLoadingReserved,
    isUninitialized: isUninitializedReserved,
  } = useGetCmsRecordByContentNameQuery(RESERVED_GROUP_EVENTS_PAGE)

  const shouldDisplayContent =
    !isEditing &&
    !isLoadingAvailable &&
    !isLoadingReserved &&
    !isUninitializedAvailable &&
    !isUninitializedReserved
  const isEditMode =
    Boolean(contentDataAvailable) &&
    Boolean(contentDataReserved) &&
    isAdminLogged &&
    isEditing

  return (
    <Page>
      <Conditional
        shouldRender={!isAdminLogged || (isAdminLogged && !isEditing)}
      >
        <div className={stylesList.buttonsDiv}>
          <Button
            className={
              isAvailable ? stylesList.btnActive : stylesList.btnNotActive
            }
            theme="secondary"
            onClick={() => setIsAvailable(true)}
          >
            {dateYear} Records
          </Button>
          <Button
            className={
              isAvailable ? stylesList.btnNotActive : stylesList.btnActive
            }
            theme="secondary"
            onClick={() => setIsAvailable(false)}
          >
            Reserved Records
          </Button>
        </div>
      </Conditional>
      <BorderedContainer>
        <Loader
          active={isLoadingAvailable || isLoadingReserved}
          style={{
            top: '50%',
            left: 'calc(50% - 30px)',
          }}
          className="z-999"
        />
        <Title className="pb3 pb4-l mb2">
          {isAvailable ? AVAILABLE_PAGE_TITLE : RESERVED_PAGE_TITLE}{' '}
          <AdminCKEditorPencil onClick={ClickEditToggle} />
        </Title>

        <Conditional shouldRender={isEditMode}>
          <CmsEditForm
            contentData={
              isAvailable
                ? (contentDataAvailable as CmsDataToUpdate)
                : (contentDataReserved as CmsDataToUpdate)
            }
          />
        </Conditional>
        <Conditional shouldRender={shouldDisplayContent}>
          <Interweave
            content={
              isAvailable
                ? contentDataAvailable?.content
                : contentDataReserved?.content
            }
            className={styles.contentSpan}
          />
        </Conditional>
      </BorderedContainer>
    </Page>
  )
}
