import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { kwrApi, useLoginToAccountMutation } from 'store/api'

import BorderedContainer from 'components/BorderedContainer'
import Form from 'components/Form'
import ImageDecoration, {
  ImageDecorationType,
} from 'components/ImageDecoration'
import SubmitButton from 'components/SubmitButton'
import Button from 'components/Button'
import Title from 'components/Title'
import { Page } from 'components/Page'
import ShadowModal from 'components/ShadowModal'
import { FormInput } from 'components/Form/FormInput'
import * as localStorage from '../../utils/localStorage'
import { useDispatch } from 'react-redux'

import './LoginToUser.css'

interface LoginData {
  email: string
}

function LogInToUser() {
  const [loginToAccount, { data, isSuccess, isLoading }] =
    useLoginToAccountMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const { handleSubmit, control } = useForm<LoginData>()

  const onSubmit = ({ email }: LoginData) => {
    loginToAccount(email.trim().toLowerCase())
  }
  const onClickModal = () => {
    if (data) {
      setIsLoadingLogin(true)
      localStorage.set('AUTH_TOKEN', data.authToken)
      dispatch(kwrApi.util.invalidateTags(['MyProfile']))
      setIsLoadingLogin(false)
      navigate('/profile', {
        state: { fromAdmin: 'fromAdminToParent' },
        replace: true,
      })
    }
  }

  return (
    <Page>
      <BorderedContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Log In to parent account</Title>
          <div className="mt4">
            <FormInput name="email" label="Email" control={control} />
          </div>
          <div className="flex h-100 mv4 flex-column items-center justify-end justify-start-l mt5-l">
            <SubmitButton className="w-100 w5-l" disabled={isLoading}>
              LOG IN
            </SubmitButton>
          </div>
        </Form>
      </BorderedContainer>
      <ImageDecoration
        type={ImageDecorationType.GIRL_RUNNING}
        position="left"
      />
      <ShadowModal isModalOpen={isSuccess}>
        <div className="body-l-t-u">
          <p className="pin">PIN: {data?.pin}</p>
          <Button
            className="bottom-btn"
            onClick={onClickModal}
            disabled={isLoadingLogin}
          >
            LOG IN
          </Button>
        </div>
      </ShadowModal>
    </Page>
  )
}

export default LogInToUser
