import React from 'react'

import { Product } from 'data/Product'

import Button from 'components/Button'
import InfoCard from 'components/InfoCard'

import './buy-container.css'

interface BuyContainerProps {
  product: Product
  disabled: boolean
}

const BuyContainer: React.FC<BuyContainerProps> = ({ product, disabled }) => {
  const formattedPrice = `$${product.price}`
  const priceColor = disabled ? 'gray' : 'black'
  const infoCardId = 'info-card' + product.id

  const handleDisabledClick = (e: any) => {
    if (disabled) {
      e.preventDefault()

      const infoCardDom = document.getElementById(infoCardId)

      if (infoCardDom) {
        infoCardDom.style.visibility = 'visible'
      }
    }
  }

  return (
    <div style={{ maxHeight: '100px' }}>
      <div className="flex flex-column justify-between">
        <div className="flex flex-row justify-between pa3">
          <div className="" style={{ height: '42px' }}>
            <Button
              className="buy-container__button"
              endIcon={
                <img
                  src="/assets/shopping-cart.svg"
                  alt="a trophy representing the record to be claimed"
                />
              }
              theme={disabled ? 'quaternary' : 'primary'}
              onClick={handleDisabledClick}
            >
              BUY NOW
            </Button>
          </div>
          <p className={`buy-container__price-tag ${priceColor} nunito-bold`}>
            {formattedPrice}
          </p>
        </div>
        <InfoCard id={infoCardId} />
      </div>
    </div>
  )
}

export default BuyContainer
