import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'

import { useGetMyLegacyProfileQuery } from 'store/api'
import { PublicRecord } from 'hooks/useRecordApi.hook'
import { AmbassadorBadge } from 'components/AmbassadorBadge'
import Conditional from 'components/Conditional'
import { RecordImage } from './RecordImage'
import { BreakRecordButton } from './BreakRecordButton'
import { LikeButton } from './LikeButton'
import { PriorityButton } from './PriorityButton'
import { buildRecordOwnerInfo, getIsRestricted } from './RecordCard.utils'
import { RecordSetter } from './RecordSetter'

import styles from './RecordCard.module.scss'

interface RecordCardProps {
  record: PublicRecord
}

export const RecordCardComponent: FC<RecordCardProps> = ({ record }) => {
  const { data: profile } = useGetMyLegacyProfileQuery()
  const recordOwnerInfo = buildRecordOwnerInfo(record)
  const shouldRestrict = getIsRestricted(profile, record)
  return (
    <Link to={`/records/${record.id}`}>
      <div className={styles.recordCard}>
        <AmbassadorBadge
          className={styles.badge}
          level={record.ambassadorLvl}
        />
        <Conditional shouldRender={record.isFirstRecord}>
          <RecordSetter />
        </Conditional>
        <RecordImage src={record.previewImageSrc} />
        <div className={styles.cardContent}>
          <div>
            <span className={styles.recordOwnerInfo}>{recordOwnerInfo}</span>
            <h2 className={styles.idea}>{record.idea}</h2>
            <div className={styles.description}>{record.description}</div>
          </div>
          <div className={styles.recordButtons}>
            <BreakRecordButton
              record={record}
              quizId={record.quizId}
              disabled={record.isBroken}
              shouldRestrict={shouldRestrict}
            />
            <LikeButton
              isLiked={record.isLiked}
              recordId={record.id}
              likeAmount={record.likeAmount}
            />
            <PriorityButton priority={record.priority} />
          </div>
        </div>
      </div>
    </Link>
  )
}

export const RecordCard = memo(RecordCardComponent)
