import React, { FC, memo } from 'react'
import BreakRecordInfo from '../BreakRecordInfo'
import { RestrictComponent } from './RestrictComponent'
import { BreakRecordSteps } from './BreakRecordSteps'
import { useGetAuthProfileQuery } from '../../../store/api'
import { AccountRole } from '../../../store/api/types/Auth.types'

const BreakRecordBodyComponent: FC = () => {
  const { data: profile } = useGetAuthProfileQuery()

  const isAuthenticated = Boolean(profile?.role)
  const isChildProfile = profile?.role === AccountRole.CHILD

  if (!isAuthenticated) {
    return <BreakRecordInfo />
  }

  if (isAuthenticated && !isChildProfile) {
    return <RestrictComponent />
  }

  return <BreakRecordSteps />
}

export const BreakRecordBody = memo(BreakRecordBodyComponent)
